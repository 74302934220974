.fixed {
    position: fixed;
    top:0;
    
    
    
    border: 3px solid #73AD21;
  }

  .instagram2{
    color:white;
    background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    }

    .instagram1{
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
      -webkit-background-clip: text;
              /* Also define standard property for compatibility */
              background-clip: text;
      -webkit-text-fill-color: transparent;
      
      font-size: 200px; /* change this to change the size*/
      
    }

    .instagram {
      font-size: var(--size);
      width: 1em;
      aspect-ratio: 1;
      background: radial-gradient(
          circle farthest-corner at 28% 100%,
          var(--yellow) 0%,
          var(--yellow_to) 10%,
          var(--orange) 22%,
          var(--red) 35%,
          transparent 65%
        ),
        linear-gradient(145deg, var(--blue) 10%, var(--purple) 70%);
      border-radius: 0.2em;
      position: relative;
      display: grid;
      place-items: center;
      &:before,
      &:after {
        content: "";
        color: var(--white);
        position: absolute;
        border-radius: inherit;
        aspect-ratio: 1;
      }
      &:before {
        border: 0.05em solid;
        width: 65%;
      }
      &:after {
        border-radius: 50%;
        border: 0.05em solid;
        width: 35%;
        box-shadow: 0.22em -0.22em 0 -0.18em;
      }
    }